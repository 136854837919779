import { Flex, Skeleton, VStack } from '@chakra-ui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { type ReactNode } from 'react';
import { P, match } from 'ts-pattern';

import { GetImpactFundDistributionRoundSummary } from '@endaoment-frontend/api';
import { useAuth, useAuthType, useWalletModal } from '@endaoment-frontend/authentication';
import { useOpenDonationWizard } from '@endaoment-frontend/donation-wizard';
import { aggregateFundTotals } from '@endaoment-frontend/funds';
import { getChainNameForChainId } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { FundListing, UUID } from '@endaoment-frontend/types';
import { ChainIcon, CollaborateIcon } from '@endaoment-frontend/ui/icons';
import { Button, MultiProgressBar, TiltBox, Tooltip, cardClassNames } from '@endaoment-frontend/ui/shared';
import { AggregatedFundAllocationBar, FundAllocationBar } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatNumber, formatUsdc } from '@endaoment-frontend/utils';

import styles from './FundStatsCard.module.scss';

const ContainerWrapper = ({
  href,
  asCard,
  children,
  className,
  enableTilt,
}: {
  href?: string;
  asCard: boolean;
  children?: ReactNode;
  className?: string;
  enableTilt: boolean;
}) => {
  if (href && asCard)
    return (
      <Link href={href}>
        <TiltBox className={clsx(asCard ? cardClassNames.base : undefined, className)} enabled={enableTilt}>
          {children}
        </TiltBox>
      </Link>
    );
  if (href)
    return (
      <Link href={href} className={className}>
        {children}
      </Link>
    );
  return (
    <TiltBox className={clsx(asCard ? cardClassNames.base : undefined, className)} enabled={enableTilt}>
      {children}
    </TiltBox>
  );
};

type MinimalFund = Pick<
  FundListing,
  | 'chainId'
  | 'grantsGiven'
  | 'id'
  | 'inTransitBuyUsdcAmount'
  | 'inTransitSellUsdcAmount'
  | 'investedUsdc'
  | 'name'
  | 'processingTransfersTotalUsdc'
  | 'totalGrantedUsdc'
  | 'type'
  | 'usdcBalance'
  | 'vanityUrl'
>;

export const FundStatsCard = ({
  fund,
  isCollaboratorOnFund = false,
  asCard = false,
  isLabelled = false,
  showZeroImpact = false,
  children,
  className,
  enableTilt = false,
}: {
  fund: Array<MinimalFund> | MinimalFund;
  isCollaboratorOnFund?: boolean;
  asCard?: boolean;
  isLabelled?: boolean;
  showZeroImpact?: boolean;
  children?: ReactNode;
  className?: string;
  enableTilt?: boolean;
}) => {
  const openDonationWizard = useOpenDonationWizard();
  const { isSignedIn } = useAuth();
  const { isWalletAuth } = useAuthType();
  const { showWallet } = useWalletModal();

  const aggregatedTotals = aggregateFundTotals(fund);
  const isSingle = !Array.isArray(fund);

  let href: string | undefined;
  if (isLabelled && isSingle) {
    href = routes.app.fund({ id: fund.id, vanityUrl: fund.vanityUrl });
  }

  const title = match({ isSingle, isLabelled, showZeroImpact, impact: aggregatedTotals.impact })
    .with({ isSingle: true, isLabelled: true }, () => {
      if (!isSingle) throw new Error('Invalid state');
      return (
        <h4 className={clsx(styles['title'], styles['title--single-fund'])}>
          <b>
            {fund.name}
            {!!isCollaboratorOnFund && (
              // TODO: Better content
              <Tooltip content='You are a collaborator on this fund and can only make recommendations.'>
                <CollaborateIcon />
              </Tooltip>
            )}
            {!!isWalletAuth && (
              <Tooltip
                content={`This fund is deployed on ${getChainNameForChainId(fund.chainId)}`}
                className={styles['chain-icon']}>
                <ChainIcon chainId={fund.chainId} filled light />
              </Tooltip>
            )}
          </b>
          <span>{`${fund.grantsGiven ?? 0} Grants`}</span>
        </h4>
      );
    })
    .with(P.union({ showZeroImpact: true }, { showZeroImpact: false, impact: P.not(0n) }), () => (
      <h4 className={styles['title']}>
        {formatCurrency(formatUsdc(aggregatedTotals.grantableBalance), { compact: true, lowercase: true })}
        <span>Total Fund Balance</span>
      </h4>
    ))
    .otherwise(() => null);
  const impactOrButton = match({ isLabelled, showZeroImpact, children, impact: aggregatedTotals.impact })
    .with(
      P.intersection({ isLabelled: true }, P.union({ showZeroImpact: true }, { showZeroImpact: false, impact: 0n })),
      () => null,
    )
    .with({ isLabelled: true, impact: P.not(0n) }, () => (
      <p className={styles['impact']}>
        {formatCurrency(formatUsdc(aggregatedTotals.grantableBalance), { compact: true, lowercase: true })}
        <span>Fund Balance</span>
      </p>
    ))
    .with({ isLabelled: false, children: P.nullish }, () => (
      <Button
        variation='fund'
        filled
        size='medium'
        className={styles['donate-button']}
        onClick={() => {
          if (!isSignedIn) {
            void showWallet();
            return;
          }
          openDonationWizard();
        }}>
        Donate Now
      </Button>
    ))
    .otherwise(() => children);

  return (
    <ContainerWrapper
      className={clsx(className, styles['container'], asCard && styles['container--card'])}
      asCard={asCard}
      href={href}
      enableTilt={enableTilt}>
      {title}
      {impactOrButton}
      <div className={styles['progress']}>
        {isSingle ? (
          <FundAllocationBar fund={fund} fundId={fund.id} showGranted showPendingGrants />
        ) : (
          <AggregatedFundAllocationBar
            invested={aggregatedTotals.investedUsdc}
            cash={aggregatedTotals.usdcBalance}
            completed={aggregatedTotals.totalGrantedUsdc}
            inTransit={aggregatedTotals.inTransitBuyUsdcAmount + aggregatedTotals.inTransitSellUsdcAmount}
          />
        )}
        {!!isSingle && fund.type === 'ImpactPool' && <CurrentDistributionStat fundId={fund.id} />}
      </div>
    </ContainerWrapper>
  );
};

export const FundStatsCardSkeleton = ({ asCard = false, className }: { asCard?: boolean; className?: string }) => (
  <ContainerWrapper className={clsx(className, styles['container'])} asCard={asCard} enableTilt={false}>
    <h4 className={clsx(styles['title'], styles['title--single-fund'])}>
      <b>
        <Skeleton width={60} minH={10} borderRadius='1rem' />
      </b>
    </h4>
    <VStack alignItems='stretch' className={styles['progress']}>
      <Skeleton w='100%' minH={4} mt='0' />
      <Flex justifyContent='space-between'>
        <Skeleton width={30} minH={4} />
        <Skeleton width={30} minH={4} />
      </Flex>
    </VStack>
  </ContainerWrapper>
);

export const CurrentDistributionStat = ({ fundId }: { fundId: UUID }) => {
  const { data: roundSummary } = GetImpactFundDistributionRoundSummary.useQuery([fundId, 'current']);

  if (!roundSummary) return <></>;

  const formattedNextDistribution = `${formatCurrency(formatUsdc(roundSummary.totalDistributionUsdc))} (${formatNumber(
    roundSummary.distributionPercentage * 100,
    { digits: 2, fractionDigits: 2 },
  )}%)`;

  return (
    <>
      <MultiProgressBar
        parts={[
          { progress: roundSummary.distributionPercentage * 100, color: '#53acde' },
          { progress: 100 - roundSummary.distributionPercentage * 100, color: '#53acde20' },
        ]}
        className={styles['progress-stats__impact']}
        breakdown
      />
      <div className={styles['progress-stats']}>
        <div className={styles['progress-stats__left']}>
          <span>
            <em className={styles['progress-stats__distribution']}>{formattedNextDistribution}</em>
            &nbsp;Next Distribution
          </span>
        </div>
        <div className={styles['progress-stats__right']}>
          <span>
            <em className={styles['progress-stats__distribution']}>
              {formatCurrency(formatUsdc(roundSummary.impactPoolNav))}
            </em>
            &nbsp;Net Grantable Value
          </span>
        </div>
      </div>
    </>
  );
};
