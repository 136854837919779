import { skipToken } from '@tanstack/react-query';
import { z } from 'zod';

import { WhoAmI } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { featureFlags } from '@endaoment-frontend/config';
import { RequestHandler } from '@endaoment-frontend/data-fetching';
import { routes } from '@endaoment-frontend/routes';
import { LogoIcon, QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Card, CopyTooltip, Tooltip } from '@endaoment-frontend/ui/shared';

import styles from './ReferralCodeCard.module.scss';

const GetReferralCode = new RequestHandler('GetReferralCode', fetch => async (userId: string) => {
  const res = await fetch(`/v1/referral/${userId}/code`);
  const parsed = z
    .object({
      code: z.string(),
    })
    .parse(res);
  return parsed.code;
});

export const useReferralCode = (options?: { enabled?: boolean }) => {
  const mergedOptions = {
    enabled: true,
    ...options,
  };
  const { isSignedIn } = useAuth();
  const { data: authToken } = WhoAmI.useQuery([], {
    enabled: isSignedIn,
  });
  return GetReferralCode.useQuery(authToken ? [authToken.userId] : skipToken, {
    enabled: !!authToken && mergedOptions.enabled,
  });
};

export const ReferralCodeCard = () => {
  const { data: referralCode, isPending: isPendingReferralCode } = useReferralCode();

  if (isPendingReferralCode || !referralCode) return <></>;

  const link = routes.app.refer({ code: referralCode, useFullUrl: true });
  return (
    <Card className={styles['card']}>
      <h6>Invite your friends + family to Endaoment</h6>
      {!!featureFlags.referralNotice && (
        <p>
          {featureFlags.referralNotice}
          {!!featureFlags.referralTooltip && (
            <Tooltip content={featureFlags.referralTooltip}>
              <QuestionIcon color='currentColor' width={18} height={18} />
            </Tooltip>
          )}
        </p>
      )}
      <div className={styles['link-text']}>
        <p>{link}</p>
        <CopyTooltip displayText='Copy Link' copyText={link} icon='link' buttonClassName={styles['tooltip-button']} />
      </div>
      <LogoIcon className={styles['card-logo']} />
    </Card>
  );
};
