import { useMemo } from 'react';

import { useAuth, useAuthType } from '@endaoment-frontend/authentication';
import { featureFlags } from '@endaoment-frontend/config';
import type { Address, FundListing } from '@endaoment-frontend/types';
import { Pill } from '@endaoment-frontend/ui/shared';

const getShouldDisplayFundMigrationWarning = (
  funds: Array<FundListing>,
  isSocialAuth: boolean,
  userAddress?: Address,
) => {
  // Do not display warning if feature flag is not enabled
  if (!featureFlags.chainForSocialMigrationWarning || featureFlags.chainForSocialMigrationWarning === 0) return false;

  // Do not display warning if user is a wallet user
  if (!isSocialAuth) return false;

  // Display warning if any user managed fund is on the chain that requires migration
  return funds.some(
    fund =>
      // Check if user is the manager of the fund
      userAddress === fund.manager.walletAddress &&
      // Check if fund is on the flagged chain
      fund.chainId === featureFlags.chainForSocialMigrationWarning,
  );
};

export const FundMigrationWarning = ({ funds }: { funds: Array<FundListing> }) => {
  const { isSocialAuth } = useAuthType();
  const { authAddress } = useAuth();

  // Display warning for users with 'unmigrated' funds
  const shouldDisplayFundMigrationWarning = useMemo(
    () => getShouldDisplayFundMigrationWarning(funds, isSocialAuth, authAddress),
    [authAddress, funds, isSocialAuth],
  );

  if (!shouldDisplayFundMigrationWarning) return null;

  const isMultipleFunds = funds.length > 1;
  return (
    <Pill variation='orange' size='tiny' style={{ padding: 20, marginBottom: 20, display: 'inline' }}>
      Due to recent network migrations, {isMultipleFunds ? 'some DAFs' : 'this DAF'} might not be properly functioning
      during this timeframe. Please reach out to&nbsp;
      <a
        href={`mailto:admin@endaoment.org?subject=Expedite Network Migration for Account Address ${authAddress}`}
        style={{ textDecoration: 'underline', color: '#ea6b0e' }}>
        admin@endaoment.org
      </a>
      &nbsp;to expedite the migration for your account.
      <br />
    </Pill>
  );
};
