import type { As } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Form, Formik, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { isAddress } from 'viem';
import { z } from 'zod';

import { GetUserIdentity, UpdateUserIdentity } from '@endaoment-frontend/api';
import { useAccountDisplayName, useAuth } from '@endaoment-frontend/authentication';
import { useGoogleLocation } from '@endaoment-frontend/hooks';
import { UserAvatar } from '@endaoment-frontend/multichain';
import type { UserIdentityInfo } from '@endaoment-frontend/types';
import { physicalAddressSchema } from '@endaoment-frontend/types';
import {
  CountryFormInput,
  FormInput,
  StateFormInput,
  ZipFormInput,
  validateWithZod,
} from '@endaoment-frontend/ui/forms';
import type { ButtonProps } from '@endaoment-frontend/ui/shared';
import { Button, Modal, Pill } from '@endaoment-frontend/ui/shared';
import { formatShortAddress } from '@endaoment-frontend/utils';

import styles from './ProfileEditModal.module.scss';

const schema: z.ZodSchema<UserIdentityInfo> = z.object({
  firstName: z.string().min(1, 'Please enter your first name'),
  lastName: z.string().min(1, 'Please enter your last name'),
  email: z.string().email(),
  address: physicalAddressSchema,
});

const ProfileEditForm = () => {
  const { setFieldValue } = useFormikContext<UserIdentityInfo>();
  const { ref } = useGoogleLocation(place => setFieldValue('address', place));

  return (
    <>
      <FormInput name='email' label='Communications Email' />
      <div className={styles['split-row']}>
        <FormInput name='firstName' label='First Name' />
        <FormInput name='lastName' label='Last Name' />
      </div>
      <div className={styles['row']}>
        <Pill fill size='tiny' className={styles['form-pill']}>
          Tax Information
        </Pill>
      </div>
      <div className={styles['split-row']}>
        <FormInput name='address.line1' label='Address Line 1' innerRef={ref} />
        <FormInput name='address.line2' label='Address Line 2' />
      </div>
      <CountryFormInput countryFieldName='address.country' stateFieldName='address.state' />
      <div className={styles['split-row']}>
        <StateFormInput name='address.state' countryFieldName='address.country' />
        <ZipFormInput name='address.zip' countryFieldName='address.country' />
      </div>
      <FormInput name='address.city' label='City' />
    </>
  );
};

export const AnimatedSaveButton = <Tag extends As>({
  isSubmitted,
  ...buttonProps
}: Omit<ButtonProps<Tag>, 'children'> & { isSubmitted: boolean }) => (
  <AnimatePresence mode='wait' initial={false}>
    {isSubmitted ? (
      <Button
        key='animating'
        type='button'
        variation='fund'
        filled
        float={false}
        {...buttonProps}
        className={clsx(styles['save-button'], buttonProps.className)}>
        Saved
      </Button>
    ) : (
      <Button
        key='prompting'
        type='submit'
        variation='fund'
        filled
        float={false}
        {...buttonProps}
        className={clsx(styles['save-button'], buttonProps.className)}>
        <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          Save
        </motion.span>
      </Button>
    )}
  </AnimatePresence>
);

export const ProfileEditModal = ({
  isOpen = false,
  onClose,
  userInfo,
}: {
  isOpen?: boolean;
  onClose: () => void;
  userInfo: UserIdentityInfo;
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateUserInfo } = useMutation({
    mutationKey: [UpdateUserIdentity.key],
    mutationFn: UpdateUserIdentity.execute,
  });

  const { authAddress } = useAuth();
  const { accountName, shortAccountName } = useAccountDisplayName();
  const formattedShortAddress = formatShortAddress(authAddress);

  const [isSubmitted, setSubmitted] = useState(false);
  const handleSubmit = async (values: UserIdentityInfo) => {
    const res = await updateUserInfo(values);
    GetUserIdentity.setData(queryClient, [], res);

    setSubmitted(true);
    setTimeout(() => {
      setSubmitted(false);
    }, 1000);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles['modal']}>
      <Formik initialValues={userInfo} validate={validateWithZod(schema)} onSubmit={handleSubmit}>
        <Form className={styles['form']}>
          <div className={clsx(styles['row'], styles['row--top'])}>
            <div className={styles['user-info']}>
              {!!authAddress && <UserAvatar address={authAddress} />}
              <div className={styles['wallet-info']}>
                <h6>{shortAccountName}</h6>
                {!isAddress(accountName) && <span>{formattedShortAddress}</span>}
              </div>
            </div>
          </div>
          <ProfileEditForm />
          <AnimatedSaveButton isSubmitted={isSubmitted} />
        </Form>
      </Formik>
    </Modal>
  );
};
